import { createContext, ReactNode, useContext, useState } from 'react'

const ScrollLockContext = createContext<[boolean, (x: boolean) => void]>([
  false,
  () => {},
])

export default function useScrollLock() {
  return useContext(ScrollLockContext)
}

interface SLPProps {
  children: ReactNode
}

export function ScrollLockProvider(props: SLPProps) {
  const state = useState(false)

  return (
    <ScrollLockContext.Provider value={state}>
      {props.children}
    </ScrollLockContext.Provider>
  )
}
