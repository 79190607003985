import { HTMLProps } from 'react'

export default function FSLayer(props: HTMLProps<HTMLDivElement>) {
  return (
    <div
      className="absolute left-0 top-0 w-full h-full pointer-events-none overflow-hidden"
      {...props}
    />
  )
}
