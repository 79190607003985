import { useState, useEffect } from 'react'

export type Dimension =
  | 'mbs'
  | 'mbm'
  | 'mbl'
  | 'tbs'
  | 'tbm'
  | 'desktop'
  | 'desktop1xl'
  | 'desktop2xl'

const screen = {
  mbs: 320,
  mbm: 360,
  mbl: 414,
  tbs: 440,
  tbm: 768,
  desktop: 1024,
  desktop1xl: 1280,
  desktop2xl: 1536,
}

function getCurrentDimension(): Dimension {
  if (window.innerWidth >= screen.desktop2xl) {
    return 'desktop2xl'
  } else if (window.innerWidth >= screen.desktop1xl) {
    return 'desktop1xl'
  } else if (window.innerWidth >= screen.desktop) {
    return 'desktop'
  } else if (window.innerWidth >= screen.tbm) {
    return 'tbm'
  } else if (window.innerWidth >= screen.tbs) {
    return 'tbs'
  } else if (window.innerWidth >= screen.mbl) {
    return 'mbl'
  } else if (window.innerWidth >= screen.mbm) {
    return 'mbm'
  } else {
    return 'mbs'
  }
}

export default function useViewport() {
  const [dimension, setDimension] = useState<Dimension>(getCurrentDimension())
  useEffect(() => {
    function handleResize() {
      setDimension(getCurrentDimension())
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  function matchSize(size: Dimension) {
    switch (size) {
      case 'desktop2xl':
        return window.innerWidth >= screen.desktop2xl
      case 'desktop1xl':
        return window.innerWidth >= screen.desktop1xl
      case 'desktop':
        return window.innerWidth >= screen.desktop
      case 'tbm':
        return window.innerWidth >= screen.tbm
      case 'tbs':
        return window.innerWidth >= screen.tbs
      case 'mbl':
        return window.innerWidth >= screen.mbl
      case 'mbm':
        return window.innerWidth >= screen.mbm
      case 'mbs':
        return window.innerWidth >= screen.mbs
    }
  }

  return {
    dimension,
    matchSize,
  }
}
