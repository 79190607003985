type ModuleLocalStorageKey =
  | 'config'
  | 'session'
  | 'routerHistory'
  | 'plaidLinkToken'
  | 'personaInquiryStarted'
  | 'contributionWithdraw'

const localStorageHelper = {
  get(key: ModuleLocalStorageKey) {
    const savedValue = window.localStorage.getItem(key)
    if (savedValue) {
      return JSON.parse(savedValue)
    }
  },
  save(key: ModuleLocalStorageKey, value: any) {
    window.localStorage.setItem(key, JSON.stringify(value))
  },
  clear(key: ModuleLocalStorageKey) {
    window.localStorage.removeItem(key)
  },
  clearAll() {
    this.clear('config')
    this.clear('session')
    this.clear('routerHistory')
    this.clear('plaidLinkToken')
  },
}

export default localStorageHelper
