import pick from 'lodash/pick'
import merge from 'lodash/merge'
import {
  createContext,
  ReactNode,
  useContext,
  useLayoutEffect,
  useMemo,
} from 'react'
import hexToRGB from './hexToRGB'
import useGoogleFont from './useGoogleFont'
import useModuleOptions from './useModuleOptions'
import useSession from './useSession'

const DEFAULT_THEME: Theme = {
  font: {
    'family-primary': 'atomic-default-font',
    'weight-primary-regular': '400',
    'weight-primary-bold': '500',
  },

  colors: {
    //Neutral
    neutral0: '#FFFFFF',
    neutral10: '#F8F8FA',
    neutral25: '#ECEEF2',
    neutral50: '#B3BBCB',
    neutral75: '#939EB5',
    neutral100: '#657496',
    neutral150: '#455880',
    neutral200: '#2A364E',

    //Blue
    primary5: '#F5FAFF',
    primary10: '#E6F2FF',
    primary25: '#C2E1FF',
    primary40: '#99CCFF',
    primary50: '#6BB5FF',
    primary75: '#3399FF',
    primary100: '#0080FF',
    primary150: '#006DD9',
    primary200: '#004E9C',

    //Red
    secondary5: '#FFF9F9',
    secondary10: '#FFEEED',
    secondary25: '#FFDAD8',
    secondary50: '#FFB2AD',
    secondary75: '#FF7971',
    secondary100: '#FF635D',
    secondary150: '#B53E37',
    secondary200: '#8C302A',

    //Yellow
    warning5: '#FFFBF6',
    warning10: '#FFF6EB',
    warning25: '#FFE6C9',
    warning50: '#FFD3A2',
    warning75: '#FFB35D',
    warning100: '#FFA034',
    warning150: '#D9882C',
    warning200: '#8C581D',

    //Green
    success5: '#F9FDFC',
    success10: '#E6F6F2',
    success25: '#B4E8DB',
    success50: '#8AD6C3',
    success75: '#54C3A7',
    success100: '#00A67C',
    success150: '#008765',
    success200: '#005B44',

    //Purple
    alternative5: '#F6F5FF',
    alternative10: '#EFEDFF',
    alternative25: '#DBD6FF',
    alternative50: '#B6ADFF',
    alternative75: '#9588FF',
    alternative100: '#614DFF',
    alternative150: '#4537B5',
    alternative200: '#352A8C',
  },
}

const ThemeContext = createContext<Theme>(DEFAULT_THEME)

export default function useTheme() {
  return useContext(ThemeContext)
}

export function ThemeProvider(props: { children: ReactNode }) {
  const options = useModuleOptions()
  const session = useSession()
  const theme: Theme = useMemo(() => {
    const themeConfig =
      options.theme === 'custom'
        ? session.customTheme ?? {}
        : options.theme ?? options.content ?? {}

    return merge(DEFAULT_THEME, pick(themeConfig, 'font', 'colors'))
  }, [options, session.customTheme])
  useApplyTheme(theme)
  return (
    <ThemeContext.Provider value={theme}>
      {props.children}
    </ThemeContext.Provider>
  )
}

export function DefaultThemeProvider(props: { children: ReactNode }) {
  const theme: Theme = DEFAULT_THEME
  useApplyTheme(theme)
  return (
    <ThemeContext.Provider value={theme}>
      {props.children}
    </ThemeContext.Provider>
  )
}

function useApplyTheme(theme: Theme) {
  useLayoutEffect(() => {
    for (const key in theme.colors) {
      const value = hexToRGB(theme.colors[key]).join(', ')
      document.documentElement.style.setProperty(`--color-${key}`, value)
    }

    document.documentElement.style.setProperty(
      '--font-weight-primary-regular',
      theme.font['weight-primary-regular']
    )
    document.documentElement.style.setProperty(
      '--font-weight-primary-bold',
      theme.font['weight-primary-bold']
    )

    if (theme.font['family-primary']) {
      if (theme.font['family-primary'] === 'atomic-default-font') {
        document.documentElement.style.setProperty(
          `--font-family-primary`,
          '"Gotham A", "Gotham B", system-ui, -apple-system,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"'
        )
      } else {
        document.documentElement.style.setProperty(
          `--font-family-primary`,
          theme.font['family-primary']
        )
      }
    }
  }, [theme])

  useGoogleFont(theme.font['family-primary'])
}
