import { useEffect, useReducer } from 'react'
import { createContext, Dispatch, ReactNode, useContext } from 'react'

interface AnalyticsFrame {
  page_id: string
  start_time: string
  end_time?: string
  prepopulated_fields: Array<string>
  set_fields: Array<string>
  skipped_fields: Array<string>
  dropped_off: boolean
}

export interface State {
  page_visited: Array<AnalyticsFrame>
}

type Action =
  | { type: 'OPEN_PAGE'; pageId: string }
  | { type: 'CLOSE_PAGE'; pageId: string }
  | { type: 'SET_FIELD'; fieldKey: string }
  | { type: 'RESET_SET_FIELDS'; fields: Array<string> }
  | { type: 'PREPOPULATED_FIELDS'; fieldKey?: string; fields?: Array<string> }
  | { type: 'SKIPPED_FIELDS'; fieldKey?: string; fields?: Array<string> }

const PageAnalyticsDispatchContext = createContext<Dispatch<Action>>(
  (action: Action) => undefined
)

const PageAnalyticsStateContext = createContext<State>({
  page_visited: [],
})

export default function usePageAnalytics() {
  return useContext(PageAnalyticsDispatchContext)
}

export function usePageAnalyticsState() {
  return useContext(PageAnalyticsStateContext)
}

function reducer(prevState: State, action: Action): State {
  const page_visited = prevState.page_visited
  switch (action.type) {
    case 'OPEN_PAGE':
      return {
        page_visited: page_visited.concat({
          page_id: action.pageId,
          start_time: new Date().toISOString(),
          end_time: undefined,
          prepopulated_fields: [],
          set_fields: [],
          skipped_fields: [],
          dropped_off: false,
        }),
      }
    case 'PREPOPULATED_FIELDS':
      return patchLastFrame(page_visited, (lastFrame) => ({
        prepopulated_fields: action.fieldKey
          ? Array.from(
              new Set(lastFrame.prepopulated_fields)
                .add(action.fieldKey)
                .values()
            )
          : action.fields,
      }))
    case 'SKIPPED_FIELDS':
      return patchLastFrame(page_visited, (lastFrame) => ({
        skipped_fields: action.fieldKey
          ? Array.from(
              new Set(lastFrame.skipped_fields).add(action.fieldKey).values()
            )
          : action.fields,
      }))
    case 'CLOSE_PAGE':
      return patchFrameByPageId(action.pageId, page_visited, () => ({
        end_time: new Date().toISOString(),
      }))
    case 'SET_FIELD':
      return patchLastFrame(page_visited, (lastFrame) => ({
        set_fields: Array.from(
          new Set(lastFrame.set_fields).add(action.fieldKey).values()
        ),
        skipped_fields: lastFrame.skipped_fields.filter(
          (i) => i !== action.fieldKey
        ),
      }))
    case 'RESET_SET_FIELDS':
      return patchLastFrame(page_visited, () => ({
        set_fields: action.fields,
      }))
  }
}

function patchLastFrame(
  page_visited: Array<AnalyticsFrame>,
  updater: (lastFrame: AnalyticsFrame) => any
): State {
  const prevPage_visited = page_visited.slice(0, -1)
  const lastFrame = page_visited[page_visited.length - 1]
  return {
    page_visited: [
      ...prevPage_visited,
      {
        ...lastFrame,
        ...updater(lastFrame),
      },
    ],
  }
}

function patchFrameByPageId(
  pageId: string,
  page_visited: Array<AnalyticsFrame>,
  updater: (lastFrame: AnalyticsFrame) => any
): State {
  const prevPage_visited = [...page_visited]
  const idx = prevPage_visited.map(({ page_id }) => page_id).lastIndexOf(pageId)
  const currentFrame = prevPage_visited[idx]

  prevPage_visited.splice(idx, 1, {
    ...currentFrame,
    ...updater(currentFrame),
  })

  return {
    page_visited: prevPage_visited,
  }
}

export function PageAnalyticsProvider(props: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, { page_visited: [] })

  return (
    <PageAnalyticsStateContext.Provider value={state}>
      <PageAnalyticsDispatchContext.Provider value={dispatch}>
        {props.children}
      </PageAnalyticsDispatchContext.Provider>
    </PageAnalyticsStateContext.Provider>
  )
}

export function makePayload(state: State, options: { dropped_off: boolean }) {
  return patchLastFrame(state.page_visited, (lastFrame) => ({
    end_time: new Date().toISOString(),
    dropped_off: options.dropped_off,
  }))
}

export function useInitPrepopulatedSkippedField<T>(
  fieldKey: string,
  value?: T
) {
  const dispatch = useContext(PageAnalyticsDispatchContext)

  useEffect(() => {
    dispatch({
      type: (Array.isArray(value) ? value.length > 0 : value !== undefined)
        ? 'PREPOPULATED_FIELDS'
        : 'SKIPPED_FIELDS',
      fieldKey,
    })
  }, [dispatch]) // eslint-disable-line react-hooks/exhaustive-deps
}

export function useInitPrepopulatedSkippedFields<T>(
  fieldKeyMap: Map<string, string>,
  value?: T
) {
  const dispatch = useContext(PageAnalyticsDispatchContext)

  useEffect(() => {
    const skipped_fields: string[] = []
    const prepopulated_fields: string[] = []

    fieldKeyMap.forEach((fieldKey, field) =>
      value?.[field as keyof T] !== undefined
        ? prepopulated_fields.push(fieldKey)
        : skipped_fields.push(fieldKey)
    )
    prepopulated_fields.length &&
      dispatch({
        type: 'PREPOPULATED_FIELDS',
        fields: prepopulated_fields,
      })
    skipped_fields.length &&
      dispatch({
        type: 'SKIPPED_FIELDS',
        fields: skipped_fields,
      })
  }, [dispatch]) // eslint-disable-line react-hooks/exhaustive-deps
}
