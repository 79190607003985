import { ActionBar, ExitBtn } from '.'

interface ErrorPageProps {
  error: Error
  onExit: () => void
}

export default function ErrorPage({ onExit }: ErrorPageProps) {
  return (
    <div className="relative w-screen h-screen pl-safe pr-safe">
      <div className="h-full w-full pt-safe pr-safe pb-safe pl-safe overflow-hidden">
        <div className="px-margin h-full">
          <ActionBar className="flex justify-end">
            <ExitBtn onClick={onExit} />
          </ActionBar>

          {/* ErrorPage is created before InltProvider is setup. */}
          {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
          <div className="mt-64 text-center text-neutral200 font-sans">
            Something went wrong. Please try again later.
          </div>
        </div>
      </div>
    </div>
  )
}
