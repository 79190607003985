import { useIntl } from 'react-intl'

interface CurrencyNumberProps {
  value: number
}

export default function CurrencyNumber(props: CurrencyNumberProps) {
  const intl = useIntl()

  const [integerDigits, fractionDigits] = intl
    .formatNumber(props.value, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    })
    .split('.')

  return (
    <span className="CurrencyNumber">
      <span className="CurrencyNumber-integerDigits">{integerDigits}</span>
      {fractionDigits && fractionDigits.length > 0 && (
        // eslint-disable-next-line formatjs/no-literal-string-in-jsx
        <span className="CurrencyNumber-fractionDigits">.{fractionDigits}</span>
      )}
    </span>
  )
}
