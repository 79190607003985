import CurrencyNumber from './core/CurrencyNumber'
import DeltaNumber from './DeltaNumber'
import { DataPoint } from '../shared/time-series'

interface MarketPriceProps {
  title?: string
  data: DataPoint[]
  selectedIndex: number
  asset: any
}

export default function MarketPrice(props: MarketPriceProps) {
  const price =
    props.data.length > 0
      ? getPrice(props.data, props.selectedIndex)
      : props.asset.value
  const deltaAbs = getDeltaAbs(props.data, props.selectedIndex)
  const deltaRel = getDeltaRel(props.data, props.selectedIndex)

  return (
    <div className="w-full">
      {props.title && (
        <div className="text-neutral100 text-o2">{props.title}</div>
      )}
      <div className="mt-2 text-h3" data-e2e="investment-value">
        <CurrencyNumber value={price} />
      </div>
      <div className="mt-1 text-s3">
        <DeltaNumber
          deltaAbsValue={deltaAbs}
          deltaRelValue={deltaRel}
          disabled={props.data.length === 0}
        />
      </div>
    </div>
  )
}

export function getPrice(data: DataPoint[], selectedIndex: number) {
  if (data.length === 0) {
    return 0
  }

  if (selectedIndex === -1) {
    return data[data.length - 1].value
  }

  return data[selectedIndex]?.value || 0
}

export function getDeltaAbs(data: DataPoint[], selectedIndex: number) {
  if (data.length < 2) {
    return 0
  }

  const selectedPoint =
    selectedIndex === -1 ? data[data.length - 1] : data[selectedIndex]

  if (!selectedPoint) {
    return 0
  }

  return selectedPoint.value - data[0].value
}

export function getDeltaRel(data: DataPoint[], selectedIndex: number) {
  if (data.length < 2) {
    return 0
  }

  const selectedPoint =
    selectedIndex === -1 ? data[data.length - 1] : data[selectedIndex]

  if (!selectedPoint) {
    return 0
  }

  if (data[0].value === 0) {
    return 0
  }

  return selectedPoint.value / data[0].value - 1
}
