import { ReactNode } from 'react'
import { FSLayer } from '..'
import useViewport from '../../shared/useViewport'

interface FooterProps {
  className?: string
  noBg?: boolean
  desktopPadding?: string
  children: ReactNode
  alwaysBottom?: boolean
}

export function Footer(props: FooterProps) {
  const {
    noBg = false,
    children,
    className = '',
    desktopPadding = 'tbm:pt-15 desktop:pt-16',
  } = props
  const viewport = useViewport()

  return !viewport.matchSize('tbm') || props.alwaysBottom ? (
    <FSLayer>
      <div
        className="absolute w-full left-0 bottom-0 pb-safe pointer-events-auto"
        style={{
          zIndex: 1,
          ...(!noBg && {
            background:
              'linear-gradient(180deg, rgba(var(--color-neutral0), 0) 0%, rgb(var(--color-neutral0)) 50%)',
          }),
        }}
      >
        <div className="footer-content w-full px-margin pt-2 pb-3 flex flex-col items-center tbs:pb-6 tbs:max-w-column tbs:mx-auto tbs:px-0">
          {children}
        </div>
      </div>
    </FSLayer>
  ) : (
    <>
      <div
        className={`left-0 bottom-0 pb-safe pointer-events-auto relative w-auto max-w-[1025px] margin-0-auto m-auto ${className}`}
        style={{
          zIndex: 1,
          ...(!noBg && {
            background:
              'linear-gradient(180deg, rgba(var(--color-neutral0), 0) 0%, rgb(var(--color-neutral0)) 50%)',
          }),
        }}
      >
        <div
          className={`footer-content w-auto flex flex-col items-center ${desktopPadding}`}
        >
          {children}
        </div>
      </div>
    </>
  )
}

export default Footer
