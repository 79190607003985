export default function createBaseURL(
  mode: Mode,
  cluster: Cluster,
  api: string
) {
  if (api) {
    return api
  }

  const parts = []

  if (mode !== 'production') {
    parts.push(mode)
  }

  parts.push(cluster === 'prod' ? 'api' : cluster)
  parts.push('atomicvest.com')

  return `https://${parts.join('.')}`
}
