import React, { ErrorInfo, ReactNode, SVGProps } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
}

export default class PageErrorBoundary extends React.Component<Props, State> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return <ErrorScreen />
    }

    return this.props.children || null
  }
}

function ErrorScreen() {
  const intl = useIntl()
  const history = useHistory()

  return (
    <div className="w-full h-full flex flex-col items-center px-margin py-6">
      <div className="w-36 h-36 mt-36 text-h2 bg-primary10 bg-opacity-50 rounded-full flex justify-center items-center">
        <BarrierIcon />
      </div>

      <div className="mt-28 text-b2 text-neutral100 text-center">
        <p>{intl.formatMessage({ defaultMessage: 'Something went wrong.' })}</p>
      </div>

      <div className="mt-6" onClick={() => history.goBack()}>
        <button
          type="button"
          className="text-s3 text-primary100 text-center"
          onClick={() => history.goBack()}
        >
          {intl.formatMessage({ defaultMessage: 'Go back' })}
        </button>
      </div>
    </div>
  )
}

function BarrierIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="84" height="84" viewBox="0 0 84 84" {...props}>
      <path d="M0 5.25H84V21H0V5.25Z" className="text-primary50 fill-current" />
      <path
        d="M10.5 0H15.75V78.75H10.5V0Z"
        className="text-primary50 fill-current"
      />
      <path
        d="M68.25 0H73.5V78.75H68.25V0Z"
        className="text-primary50 fill-current"
      />
      <path
        d="M44.625 21H28.875L39.375 5.25H55.125L44.625 21Z"
        className="text-primary25 fill-current"
      />
      <path
        d="M15.75 21H0L10.5 5.25H26.25L15.75 21Z"
        className="text-primary25 fill-current"
      />
      <path
        d="M73.5 21H57.75L68.25 5.25H84L73.5 21Z"
        className="text-primary25 fill-current"
      />
      <path
        d="M0 31.5H84V47.25H0V31.5Z"
        className="text-primary50 fill-current"
      />
      <path
        d="M44.625 47.25H28.875L39.375 31.5H55.125L44.625 47.25Z"
        className="text-primary25 fill-current"
      />
      <path
        d="M15.75 47.25H0L10.5 31.5H26.25L15.75 47.25Z"
        className="text-primary25 fill-current"
      />
      <path
        d="M73.5 47.25H57.75L68.25 31.5H84L73.5 47.25Z"
        className="text-primary25 fill-current"
      />
      <path
        d="M10.5 31.5H15.75V47.25H10.5V31.5Z"
        className="text-primary100 fill-current"
      />
      <path
        d="M68.25 31.5H73.5V47.25H68.25V31.5Z"
        className="text-primary100 fill-current"
      />
      <path
        d="M10.5 5.25H15.75V21H10.5V5.25Z"
        className="text-primary100 fill-current"
      />
      <path
        d="M68.25 5.25H73.5V21H68.25V5.25Z"
        className="text-primary100 fill-current"
      />
      <path
        d="M0 78.75H84V84H0V78.75Z"
        className="text-primary25 fill-current"
      />
    </svg>
  )
}
