import { createContext, ReactNode, useContext } from 'react'
import isEmpty from 'lodash/isEmpty'
import ConfigErrorsPage from 'shared/config-errors/ConfigErrorsPage'
import { ConfigReadErrors } from './config'

const ModuleOptionsContext = createContext<any>(undefined)

export default function useModuleOptions(): ModuleOptions {
  const context = useContext(ModuleOptionsContext)
  if (!context) {
    throw new Error('ModuleOptionsContext is not defined')
  }
  return context
}

interface ModuleOptionsProviderProps {
  options: ModuleOptions
  errors: ConfigReadErrors
  children: ReactNode
}

export function ModuleOptionsProvider(props: ModuleOptionsProviderProps) {
  if (!isEmpty(props.errors)) {
    return <ConfigErrorsPage errors={props.errors} />
  }

  return (
    <ModuleOptionsContext.Provider value={props.options}>
      {props.children}
    </ModuleOptionsContext.Provider>
  )
}
