import useFlags from './useFlags'
import useModuleOptions from './useModuleOptions'
import { isIOSWebView, isReactNativeWebView } from './usePostMessageChannel'
import { createContext, ReactNode, useContext } from 'react'

// List all features for type checking
export type Feature =
  | 'dev-hide-stock-logo'
  | 'dev-lock-scroll'
  | 'dev-simulate-expired-session'
  | 'dev-suppress-page-animation'
  | 'disable-auto-skip'
  | 'allow-crypto'
  | 'allow-trading'
  | 'hide-exit-button'
  | 'allow-ssn-prefill'
  | 'hide-desktop-back-button'
  | 'plaid-oauth-redirect-flow'
  | 'dev-force-contribution-state-no-contribution'
  | 'dev-force-contribution-state-account-disconnected'
  | 'dev-force-contribution-state-account-missing-bank-list-empty'
  | 'dev-force-contribution-state-account-missing-bank-list-exist'
  | 'dev-force-contribution-state-requested'
  | 'dev-force-contribution-state-scheduled'
  | 'dev-force-contribution-state-processing'
  | 'dev-force-contribution-state-account-insufficient-balance'
  | 'dev-force-contribution-state-canceled'
  | 'dev-force-contribution-state-expired'
  | 'dev-force-contribution-state-failed'
  | 'dev-force-contribution-state-account-processing'
  | 'dev-force-contribution-state-account-balance-update-required'
  | 'dev-force-state-account-pending'
  | 'dev-enable-onboarding-confirm-errors'
  | 'dashboard-identity-banner'
  | 'disable-contribution-page'
  | 'disable-withdrawal-page'
  | 'allow-international'
  | 'disable-linking-new-account'
  | 'disable-self-directed'
  | 'disable-email-requests'
  | 'disable-complete-account-on-error'
  | 'enable-corp-dashboard'
  | 'enable-kyc-retry'
  | 'enable-corporate-kyc-retry'
  | 'disable-disclosures-footer'
  | 'disable-onboarding-confirmation'

// List all enabled features for runtime checking
const FEATURE_WHITELIST: Feature[] = [
  'dev-hide-stock-logo',
  'dev-lock-scroll',
  'dev-simulate-expired-session',
  'dev-suppress-page-animation',
  'disable-auto-skip',
  'allow-crypto',
  'allow-trading',
  'hide-exit-button',
  'allow-ssn-prefill',
  'hide-desktop-back-button',
  'plaid-oauth-redirect-flow',
  'dev-force-contribution-state-no-contribution',
  'dev-force-contribution-state-account-disconnected',
  'dev-force-contribution-state-account-missing-bank-list-empty',
  'dev-force-contribution-state-account-missing-bank-list-exist',
  'dev-force-contribution-state-requested',
  'dev-force-contribution-state-scheduled',
  'dev-force-contribution-state-processing',
  'dev-force-contribution-state-account-insufficient-balance',
  'dev-force-contribution-state-canceled',
  'dev-force-contribution-state-expired',
  'dev-force-contribution-state-failed',
  'dev-force-contribution-state-account-processing',
  'dev-force-contribution-state-account-balance-update-required',
  'dev-force-state-account-pending',
  'dev-enable-onboarding-confirm-errors',
  'dashboard-identity-banner',
  'disable-contribution-page',
  'disable-withdrawal-page',
  'allow-international',
  'disable-linking-new-account',
  'disable-self-directed',
  'disable-email-requests',
  'disable-complete-account-on-error',
  'enable-corp-dashboard',
  'enable-kyc-retry',
  'enable-corporate-kyc-retry',
  'disable-disclosures-footer',
  'disable-onboarding-confirmation',
]

const FEATURE_ENFORCED: Feature[] = [
  'disable-email-requests',
  'enable-corporate-kyc-retry',
]

// We force Plaid to use redirect flow on mobile devices.
if (isReactNativeWebView() || isIOSWebView()) {
  FEATURE_ENFORCED.push('plaid-oauth-redirect-flow')
}

/**
 * Features let enable/disable some part of the app dynamically.
 * We get features from next sources (priority first):
 *  - FEATURE_ENFORCED
 *  - LaunchDarkly flags
 *  - `features` array from module config
 * Note, LaunchDarkly flags can override `features` from module config.
 *
 * Expected flags from LaunchDarkly:
 *  - boolean: true | false
 *  - string: 'unset' | 'on' | 'off'
 *
 * String flag is useful to explicitly disable feature (not possible with boolean flag).
 */
const FeaturesContext = createContext<string[]>([])

export default function useFeature(featureId: Feature): boolean {
  const context = useContext(FeaturesContext)
  const flags = useFlags()
  const enforced = FEATURE_ENFORCED.slice()

  if (!FEATURE_WHITELIST.includes(featureId)) {
    throw new TypeError(
      `Invalid feature: ${featureId}, available features: ${FEATURE_WHITELIST.join(
        ', '
      )}`
    )
  }

  // 1. Check if we enforce feature
  if (enforced.includes(featureId)) {
    return true
  }

  // 2. Check if LaunchDarkly has boolean feature turned on
  if (flags[featureId]) {
    return true
  }

  // 3. Check if LaunchDarkly has string feature turned on
  if (flags[featureId] === 'on') {
    return true
  }

  // 3. Check if LaunchDarkly has string feature turned off
  if (flags[featureId] === 'off') {
    return false
  }

  // 4. Check if module config has the feature enabled
  if (context.includes(featureId)) {
    return true
  }

  // 5. None of the above applies, feature is disabled
  return false
}

interface FeatureProps {
  children: ReactNode
}

export function FeaturesProvider(props: FeatureProps) {
  const { features } = useModuleOptions()
  return (
    <FeaturesContext.Provider value={features}>
      {props.children}
    </FeaturesContext.Provider>
  )
}
