import { useEffect } from 'react'

const notGoogleFont = ['atomic-default-font']

export default function useGoogleFont(fontFamily?: string) {
  useEffect(() => {
    if (fontFamily && !notGoogleFont.includes(fontFamily)) {
      // In the module we use normal (400) and medium (500) font weights
      // This may be outdated, so keep an eye in the usage
      const href =
        '//fonts.googleapis.com/css?family=' + fontFamily + ':400,500'
      let link = document.querySelector('#use-css-link')

      if (!link) {
        link = document.createElement('link')
        link.id = 'use-css-link'
        link.setAttribute('rel', 'stylesheet')
        link.setAttribute('type', 'text/css')
        document.head.appendChild(link)
      }

      link.setAttribute('href', href)
    }
  }, [fontFamily])
}
