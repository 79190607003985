import { ReactNode, isValidElement, cloneElement } from 'react'
import classNames from 'classnames'

export function createComponent(
  node: ReactNode,
  callback: (childClassName: string) => any
) {
  return isValidElement(node)
    ? cloneElement(node, {
        className: callback(node?.props?.className),
      })
    : null
}

export function addClassName(
  classNamesToAdd: string | Array<string | undefined>,
  options: { node: ReactNode }
) {
  return createComponent(options.node, (childClassName) =>
    classNames(childClassName, classNamesToAdd)
  )
}
